// Module
import * as React from "react";
import { Link,navigate } from "gatsby"

// Style
import "./customButton.scss";

// Components
import Button from "reactstrap/lib/Button";

import { withTranslation, WithTranslation } from 'react-i18next';
import { t } from "i18next";
import i18n from "../../../../i18n";
// Variation of the button
// 1. Trigger <Link>
// 2. Hyperlink to external link
// 3. Trigger a custom function
// 4. A button

const changeLanguage = ( ln: string ) =>{
    return () => {
        i18n.changeLanguage(ln);
            const currentURL =  window.location.href
            let checkIsPost = currentURL.slice(currentURL.length-1)
            // let checkLanguage = currentURL.slice(22,25)
            let checkLanguage = currentURL.slice(23,26)
            // let changeUrlOrigan = currentURL.slice(0,22) + currentURL.slice(25)
            let changeUrlOrigan = currentURL.slice(0,23) + currentURL.slice(26)
            let checkIsPostAndContactCase = currentURL.slice(currentURL.length-11)
            // if (typeof window !== "undefined"  && currentURL !== 'http://localhost:8000/'){
            if (typeof window !== "undefined"  && currentURL !== 'https://dev.centreo.hk/'){
                // http://centreo-dev.s3-website-ap-southeast-1.amazonaws.com/
                // http://localhost:8000/
                // console.log(checkLanguage)
                // console.log(checkIsPostAndContactCase)
                // console.log(currentURL.slice(0,22))
                if(typeof window !== "undefined"  && checkLanguage == 'sc_' &&  checkIsPostAndContactCase !== '#contact-us'){
                    window.location.replace(
                        `${changeUrlOrigan}`
                    )
                }else if(typeof window !== "undefined" && checkLanguage == 'sc_' && checkIsPost == '/' && checkIsPostAndContactCase == '#contact-us'){
                    window.location.replace(
                        `${changeUrlOrigan}`
                    )
                }
            }
        
        // const typeof window !== "undefined" = () => typeof window !== "undefined"
        console.log(checkLanguage)
        console.log(currentURL)
        // console.log(checkIsPost)
        // console.log(`Language change to ${ln}`)
    }
}

interface ICustomButtonProps {
    text?: string;
    fnTrigger?: any;
    key?: string;
    id?: string;
    link: string;
    // onClick: any;
    variation: "primitive" | "theme" | "affirmative" | "alertwarning";
}
interface IProps extends WithTranslation {
    prop: any;
}

interface IState {
    state: any;
}
class LanguageButtonEn extends React.PureComponent<
    ICustomButtonProps,IProps , IState
    > {

    public componentDidMount() {

        }
    public button() {

        return (
            <Button
                className={"custom-button" + " " + this.props.variation}
                onClick={ changeLanguage( "en" ) }
                key={this.props.key ? this.props.key : ""}
                id={this.props.id}
            >
                <span className="button-text text-light">
                    {this.props.text ? this.props.text : t(`En`)}
                </span>
            </Button>
        );
    }
    public render() {
        let buttonType: "int-link" | "ext-href" | "primitive";
        if (
            this.props.link.match("^http") ||
            this.props.link.match("^mailto")
        ) {
            buttonType = "ext-href";
        } else if (this.props.link.match("^/")) {
            buttonType = "int-link";
        } else {
            buttonType = "primitive";
        }
        
        return (
            <>
                {/* {checkLanguage == 'Sc_' ? '' : <Link to={currentURL.slice(0,21) + currentURL.slice(25)}></Link>} */}
                
                {buttonType === "ext-href" && (
                    <a href={this.props.link}>{this.button()}</a>
                )}
                {buttonType === "int-link" && (
                    <Link to={this.props.link}>{this.button()}</Link>
                )}
                {buttonType === "primitive" && this.button()}
            </>
        );
    }
}

export default withTranslation('info')(LanguageButtonEn)