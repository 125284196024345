import i18n from "i18next"
import { Translation } from "react-i18next"
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import en from './public/locales/en/info.json';
import sc from './public/locales/sc/infosc.json';
import LanguageDetector from 'i18next-browser-languagedetector';

const resources = {
  en: {
    info: en
  },
  sc: {
    info: sc
  }
};

const DETECTION_OPTIONS = {
  order: ['localStorage', 'navigator'],
  caches: ['localStorage']
};


i18n
    .use(LanguageDetector)
    .use(Backend)
    .use(initReactI18next) 
    .init({
      resources,
      fallbackLng: "en",
      detection: DETECTION_OPTIONS,
    // // have a common namespace used around the full app
      ns: ["info","infosc"],

      debug: true,

    // interpolation: {
    //   escapeValue: false, // not needed for react!!
    // },

    // react: {
    //   wait: true,
    // },
  })

export default i18n