import * as React from "react";

// Styling imports
import "bootstrap/dist/css/bootstrap.min.css";
import "rc-pagination/assets/index.css"
import "src/styles/all.min.css";
import "src/styles/Main.scss";


// React router
import Header from "src/components/Organisms/Header/header";
import Footer from "src/components/Organisms/Footer/footer";

// Router wrapper
import ScrollToTop from "src/routes/scrollToTop";

export default (props: {
  children: JSX.Element[];
}) => (
  <ScrollToTop>
      <div>
          <Header />
          {props.children}
          <Footer />
      </div>
  </ScrollToTop>
);

